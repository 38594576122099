import React from 'react'
import { BLOCKS, MARKS, Block, Inline, Text } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
	HeroSection,
	HeroItemsContainer,
	HeroButtons,
	HeroSeeAllLink,
	HeroSeeAll,
	HeroBookLink,
	HeroBook,
	Bold,
	Heading1,
	BannerText,
	DolphinLogo,
	ClientCareLogo,
	BookDemoLink,
	BookDemo,
} from './styles'
import { Sources, DolphinBanner } from './dolphin-types'

interface HeroProp extends DolphinBanner, Sources {
	heroLineUrl: {
		file: {
			url: string
		}
		description: string
	}
}

const heroBannerOptions = {
	renderMark: {
		[MARKS.BOLD]: (text: React.ReactChild | React.ReactFragment | null | undefined) =>
			function BoldText() {
				return <Bold>{text}</Bold>
			},
	},
	renderNode: {
		[BLOCKS.HEADING_1]: function HeadingOne(
			node: Block | Inline | Text,
			children: React.ReactChild | undefined | null | React.ReactFragment
		) {
			return <Heading1>{children}</Heading1>
		},
		[BLOCKS.PARAGRAPH]: function Paragprah(
			node: Block | Inline | Text,
			children: React.ReactChild | undefined | null | React.ReactFragment
		) {
			return <BannerText>{children}</BannerText>
		},
	},
}

const Hero = ({
	mainActionText,
	mainActionUrl,
	secondActionText,
	secondActionUrl,
	thirdActionText,
	thirdActionUrl,
	description,
	heroLineUrl,
	sources,
	image,
}: HeroProp): JSX.Element => {
	return (
		<HeroSection>
			<HeroItemsContainer sources={sources}>
				<ClientCareLogo src={image?.file?.url} alt={image?.description}></ClientCareLogo>
				<DolphinLogo src={heroLineUrl?.file?.url} alt={heroLineUrl?.description}></DolphinLogo>
				{documentToReactComponents(JSON.parse(description?.raw), heroBannerOptions)}
				<HeroButtons>
					{mainActionUrl != undefined || mainActionUrl != null ? (
						<HeroSeeAllLink to={'/' + mainActionUrl}>
							{mainActionText != undefined || mainActionText != null ? <HeroSeeAll>{mainActionText}</HeroSeeAll> : ''}
						</HeroSeeAllLink>
					) : (
						''
					)}
					{/* {secondActionUrl != undefined || secondActionUrl != null ? (
						<HeroBookLink to={'/' + secondActionUrl}>
							{secondActionText != undefined || secondActionText != null ? <HeroBook>{secondActionText}</HeroBook> : ''}
						</HeroBookLink>
					) : (
						''
					)} */}
					{thirdActionUrl != undefined || thirdActionUrl != null ? (
						<BookDemoLink to={'/' + thirdActionUrl}>
							{thirdActionText != undefined || thirdActionText != null ? <BookDemo>{thirdActionText}</BookDemo> : ''}
						</BookDemoLink>
					) : (
						''
					)}
				</HeroButtons>
			</HeroItemsContainer>
		</HeroSection>
	)
}

export default Hero
